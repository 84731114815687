import React from 'react';
import './App.scss';

function App() {
    return (<section>
        <h1>
            <span role="img" aria-label="Waving hand">👋</span> Hello, Justin here.
        </h1>
        <h4>
            I'm a product designer at <a href="https://www.atlassian.com/" target="_blank" rel="noopener noreferrer">Atlassian</a>. Currently creating delightful experiences for admins from small teams to enterprises.
        </h4>
        <h4>
            Feel free to <a href="mailto:hi@justinhuang.design" target="_blank" rel="noopener noreferrer">
            drop me an email
        </a> or connect with me on <a href="https://www.linkedin.com/in/justinhuang-the-designer/" target="_blank" rel="noopener noreferrer">
            LinkedIn
        </a>.
        </h4>
    </section>);
}

export default App;
